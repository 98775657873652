.a {
  height: 100vh;
  display: flex;
  align-items: center;
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.a-right {
  flex: 1;

}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: white;
}

.a-card {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 60px;
}

.a-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
}

.a-desc {
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  font-weight: 300;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 20px;
}

.a-award {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-bg {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width:480px) {
  .a {
    flex-direction: column;
    text-align: center;
   
  }

  .a-left {
    width: 100%;
    
  }

  .a-card {
    height: 60vh;
  }

  .a-card.bg,
  .a-award {
    display: none;
  }


  .a-desc {
  
    margin-left: 5px;
    margin-right: 5px;
  }
}