.pl-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 50px;
    font-size: 60px;
}

.text {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

@media screen and (max-width: 480px) {
  

    .text {
        padding-top: 35vh;

    }

    .pl-texts {
        width: 100%;
    }

    .pl-desc {
        display: none;
    }

}