.c {
  height: 100vh;
  position: relative;
}

.c-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-miniwrapper {
  padding-top: 5%;
  font-weight: 300;
  font-size: 20px;
}

.c-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 50px;
  font-size: 60px;
}

.c-p {
  padding: 50px 0;
  padding-left: 25%;
  padding-right: 20%;
}

.c-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  font-weight: 300;
  width: 70%;
}

.c-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

form {
  margin-top: 20px;
}

input {
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;

}

textarea {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  
}

button {
  border: none;
  padding: 15px;
  background-color: #7f837e;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.contact-bg {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  background-color: #112b1b;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.social-wrapper {
  display: flex;
  justify-content: center;
}

.img-wrapper {
  display: flex;
  justify-content: center;
  gap: 10vw;
  padding: 0 50px;
  width: 100%;
}

.contact-img {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 480px) {
  .c {
    height: 100vh;
  }

  .c-wrapper {
    flex-direction: column;
    padding:  0 0 0 0;
  }
  .c-miniwrapper {
    padding-left: 5px;
    padding-right:5px;
    padding-top: 10px;
  }
  
  .c-title {
    font-size: 40px;
  }

  .c-info-item {
    margin: 20px 0px;
    width: 100%;
  }

  form {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    width: 95%;

  }

  input {
    width: 95%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }

  .contact-bg {
    display: none
  }

  .c-p {
    padding: 5px;
padding-top: 50px;
  }
  
  .img-wrapper {
    padding: 0 50px;
    padding-top: 50px;
    padding-bottom: 40px;
    width: 100%;
  }
}